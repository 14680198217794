
import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import "../App.css"

function Footer () {
  const [hover, setHover] = useState();

  return (
    <div className="App-footer">
        {/* <NavLink className="logo-box" to="/">
          <img src={'/rid-logos/Logo_Crop.png'} className="App-logo" alt="Ridio logo featuring energy bolts in the Os"/>
        </NavLink> */}

        <div className="sitemap">
          <NavLink className="App-link link-text" to="/">Home</NavLink>
          <NavLink className="App-link link-text" to="/book">Book a Ride</NavLink>
          <NavLink className="App-link link-text" to="/profile">Profile</NavLink>
          <NavLink className="App-link link-text" to="/store">Store</NavLink>
          <NavLink className="App-link link-text" to="/privacypolicy">Privacy Policy</NavLink>
          <NavLink className="App-link link-text" to="/contact">Contact</NavLink>
          <NavLink className="App-link link-text" to="/auth">Sign In</NavLink>
        </div>

        <div className="social-box" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
          {hover ? (
            <div>
              <a href="https://www.instagram.com/ridio_ev/profilecard/?igsh=MTYzZGJvamdhZzZ3MQ==">
                <img src={'/rid-icons/instagram_icon_blue.svg'} alt="instagram logo" />
              </a>
            </div>
          ) : (
            <div>
              <img src={'/rid-icons/instagram_icon_f.svg'} alt="instagram logo" />
            </div>
          )}
        </div>
      </div>
  );
}

export default Footer;