// src/App.js

import './App.css';
import { createBrowserRouter, RouterProvider, } from "react-router-dom"
import Layout from "./components/Layout"
import Page404 from "./components/Error"
import Profile from "./pages/Profile"
import Home from "./pages/Home"
import BookingPage from "./pages/BookingPage";
import Auth from "./pages/Auth";
import PrivPol from "./pages/PrivacyPolicy";
import Contact from "./pages/Contact";
import Store from "./pages/Store";
import Success from "./pages/Success";

import BookButton from "./components/BookButton";


// const stripePromise = loadStripe('pk_test_51NgtgwA91KW339c41zbHv7m5Qu8EQHNARSSjmdtRI6hBspKz3q5QSAfHx5ouD9Yt0yfTYSTuqUSB3o9ztSh7N2HT00DVg94qgo');

const RouteProvider = () =>  {
  const router = createBrowserRouter([
    {
      // parent route component
      element: <Layout />,
      // your custom routing error component
      errorElement: <Page404 />,
      // child route components
      children: [
        { path: "/", element: <Home />, },
        { path: "/profile", element: <Profile />, },
        { path: "/book", element: <BookingPage />, },
        { path: "/auth", element: <Auth />, }, 
        { path: "/privacypolicy", element: <PrivPol />, },
        { path: "/contact", element: <Contact />, }, 
        { path: "/success", element: <Success />, }, 
        { path: "//store", element: <Store />, },
      ],
    },
  ])

  return (
      <RouterProvider router={router} />
  )
}

export default RouteProvider;
